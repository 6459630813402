import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import ByteNSoftPage from './components/bytensoft-page'
import ContactPageCover from './components/contact-page-cover'
import About from './components/about-us-page'
import Recruitment from './components/recruitment-page'
import Services from './components/services-page'
import UploadResume from './components/upload-resume'

const Routes = () => {
  return (
    <ReactRoutes>
      {routes.map((item, i) => (
        <Route key={i} path={item.path} element={item.renderer()} />
      ))}
      <Route path="*" element={<Navigate replace to="/not-found-cover" />} />
    </ReactRoutes>
  );
};

const routes = [
    {
      path: '/',
      renderer: (params = {}) => <ByteNSoftPage {...params} />,
    }, 
    {
      path: '/subetucv',
      renderer: (params = {}) => <UploadResume {...params} />,
    },    
    {
      path: '/contactus',
      renderer: (params = {}) => <ContactPageCover {...params} />,
    },    
    {
      path: '/nosotros',
      renderer: (params = {}) => <About {...params} />,
    },    
    {
      path: '/reclutamiento',
      renderer: (params = {}) => <Recruitment {...params} />,
    },   
    {
      path: '/servicios',
      renderer: (params = {}) => <Services {...params} />,
    },   
     
    
  ]; 
  

export default Routes;
