/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import WhoWeAre from './about-whoweare'

const Story = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container  xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
             Quienes somos
            </Typography>
            <Typography component={'p'}>
            Somos una empresa con alcance internacional dedicada a la consultoría en tecnologías de la información y reclutamiento de personal, operando en la ciudad de Monterrey MX.
           
              <br />
              <br />
            Con más de 10 años de experiencia, somos una empresa con expertos 100% capacitados en el tema al otro lado de tu pantalla que te ayudarán a encontrar soluciones digitales
            y de talento humano que aporten valor a tu negocio de acuerdo a tus necesidades. 
              <br />
              <br />
             
            </Typography>
          </Box>
          <WhoWeAre/>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={500} width={1}>
            <Box
              component={'img'}
              src={
                'https://assets.maccarianagency.com/svg/illustrations/drawkit-illustration1.svg'
              }
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
