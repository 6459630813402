//import './App.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import Page from './components/page';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-image-lightbox/style.css';
import 'aos/dist/aos.css';

const App = () => {
  return (
    <Page>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
    </Page>
  );
}

export default App;
