import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import logo from '../../src/Bytensoft.png';
import Link from '@mui/material/Link';

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="BNS"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                ? logo 
                : 'https://assets.maccarianagency.com/the-front/logos/logo-negative.svg'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box  paddingY={1}>
            <Link
            underline="none"
            component="a"
            href="#servicios"
            color={'text.primary'}
            sx={{ display: 'flex', alignItems: 'center' }}
            >Servicios</Link>
        </Box>
        <Box  paddingY={1}>
            <Link
            underline="none"
            component="a"
            href="/reclutamiento"
            color={'text.primary'}
            sx={{ display: 'flex', alignItems: 'center' }}
            >Reclutamiento</Link>
        </Box>
        <Box  paddingY={1}>
            <Link
            underline="none"
            component="a"
            href="/subetucv"
            color={'text.primary'}
            sx={{ display: 'flex', alignItems: 'center' }}
            >Sube tu CV</Link>
        </Box>
        <Box  paddingY={1}>
          <Link
          underline="none"
          component="a"
          href="/nosotros"
          color={'text.primary'}
          sx={{ display: 'flex', alignItems: 'center' }}
          >Nosotros</Link>
        </Box>
        
        <Box marginTop={1}>
          <Button
            size={'large'}
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            target="blank"
            href="/contactus"
          >
           Contáctanos
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
