import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
//import NavItem  from './nav-item';
//import logo from '../../src/BytensoftShorten.png';
import logo from '../../src/Bytensoft.png';
import Link from '@mui/material/Link';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    servicios: serviciosPages,    
    nosotros: companyPages,    
  } = pages;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="bytesnsoft"
        
      >
        <img alt='Bytes and software' 
             src={
              mode === 'light' && !colorInvert
              ? logo
              : logo
              } 
              style={{
                width: "350px", height: "70px",
                objectFit:'cover', marginLeft:'-50px'
              }}
            ></img>
        
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box>

          <Link
            underline="none"
            component="a"
            href="/"
            color={colorInvert ? 'common.white' : 'text.primary'}
            sx={{ display: 'flex', alignItems: 'center' }}
          >Inicio</Link>
        </Box>
        <Box  marginLeft={4}>

          <Link
            underline="none"
            component="a"
            href="/servicios"
            color={colorInvert ? 'common.white' : 'text.primary'}
            sx={{ display: 'flex', alignItems: 'center' }}
          >Servicios</Link>
        </Box>
        <Box marginLeft={4}>
          <Link
            underline="none"
            component="a"
            href="/reclutamiento"
            color={colorInvert ? 'common.white' : 'text.primary'}
            sx={{ display: 'flex', alignItems: 'center' }}
          >Reclutamiento</Link>
        </Box>       
        <Box marginLeft={4}>
          <Link
            underline="none"
            component="a"
            href="/subetucv"
            color={colorInvert ? 'common.white' : 'text.primary'}
            sx={{ display: 'flex', alignItems: 'center' }}
          >Sube tu CV</Link>
        </Box>       
        <Box marginLeft={4}>
          <Link
            underline="none"
            component="a"
            href="/nosotros"
            color={colorInvert ? 'common.white' : 'text.primary'}
            sx={{ display: 'flex', alignItems: 'center' }}
          >Nosotros</Link>
        </Box>    
        <Box marginLeft={4}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            target="blank"
            href="/contactus"
            size="large"
          >
            Contáctanos
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
