const pages = {
    servicios: [
      {
        title: 'Mobile App',
        href: '/mobile-app',
      },     
    ],
    nosotros: [      
      {
        title: 'Contact: Startup',
        href: '/contact-page',
      },
    ],           
  };
  
  export default pages;
  