import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

const RecruitmentMain = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box>
          <Typography fontWeight={700} variant={'h4'} gutterBottom>
            Seremos tus aliados en el reclutamiento de personal.
            <br/>
            <Typography
            color={'primary'}
            component={'span'}
            variant="h4"
            fontWeight={700}
            sx={{
            background: `linear-gradient(180deg, transparent 82%, ${alpha(
                theme.palette.secondary.main,
                0.1,
            )} 0%)`,
            }}
              >
              Garantizamos una respuesta a tu vacante en 72 horas.*          
              </Typography>
          </Typography>
         
        </Box>
       
      </Box>
      <Divider sx={{ marginY: 4 }} />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={12}>

          <Box marginBottom={3}>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
            Nos especializamos en conseguir el talento humano para tu empresa
            </Typography>
            <Typography component={'p'}>
           Con más de 10 años de experiencia en reclutamiento, selección y manejo de personal, estos son nuestros servicios.
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
              {[
                'Reclutamiento Staff',
                'Reclutamiento especializado en Tecnologías de la información',
                'Elaboración de perfiles y descripciones laborales',
                'Estudio salarial con respecto al mercado laboral de cualquier posición (incluye estudio estadístico)',
                'Administración personal',                                        
              ].map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box >
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              Estamos comprometidos con la calidad
            </Typography>
            <Typography component={'p'} id='aplicar'>
              Sabemos que la continuidad y estabildad laboral juega un papel clave en el éxito de un empera, es por eso que todos nuestros servicios de reclutamiento 
              incluyen garantía de 40 días, si por alguna razon la continuidad de alguno de nuestros candidatos no llegara a concretarse, te ofrecemos nuevamente el servicio 
              sin costo*.  
            </Typography>
          </Box>
        </Grid>
       
      </Grid>
    </Box>
  );
};

export default RecruitmentMain;
