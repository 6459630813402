import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
//import Divider from '@mui/material/Divider';
import MainBanner from './main-banner';
import Main from './main-layout';
import Container from './container';
import Categories from './categories';
import Contact from './contact';

const ByteNSoftPage = () => {
  const theme = useTheme();

  return (
    <Main>
      <MainBanner />
      
      <Box 
        position={'relative'}
        sx={{
            backgroundColor: theme.palette.alternate.main,
        }}
      >       
        <Container style={{paddingTop: 15}}>
          <Categories />
        </Container>       
      </Box>
     
      <Box
        sx={{
          background: 'transparent',
          
        }}
      >
        <Container>
          <Contact/>
        </Container>
      </Box>
    </Main>
  );
};

export default ByteNSoftPage;
