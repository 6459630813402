import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import logo from '../../src/Bytensoft.png';

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            component="a"
            href="/"
            title="BNS"
            width={80}
          >
            
            <img alt='Bytes and software' 
            src={
             mode === 'light' 
             ? logo
             : logo
             } 
             style={{
               width: "150px", height: "80px",
               objectFit:'cover'
             }}
           ></img>
          </Box>
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
                    
            <Box marginTop={1}>
              <Button
                variant="outlined"
                color="primary"
                component="a"
                target="blank"
                href="/contactus"
                size="small"
              >
                Contáctanos
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; Bytes and Software. 2020, Todos los derechos reservados.
        </Typography>
        <Typography
          align={'center'}
          variant={'caption'}
          color="text.secondary"
          component={'p'}
        >
        © Bytes y Software. 2020, Todos los derechos reservados.
        Cuando visita o interactúa con nuestros sitios, servicios o herramientas, nosotros o nuestros proveedores
         de servicios autorizados podemos usar cookies para almacenar información para ayudarlo a brindarle una experiencia mejor, más rápida y más segura y con fines de marketing.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
