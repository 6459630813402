import React from 'react';
import Main from './main-layout';
import Container from './container';
import Hero from './about-hero';
import Story from './about-story'


const About = () => {
  return (
    <Main colorInvert={true}>
      <Hero />
      <Container>
        <Story />
      </Container>

    </Main>
  );
};

export default About;
