import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
  fullName: yup
    .string()
    .trim()
    .min(2, 'Por favor captura tu nombre completo')
    .max(50, 'Por favor captura tu nombre completo (demasiado largo)')
    .required('Por favor captura tu nombre completo'),
  message: yup
    .string()
    .trim()
    .max(500, 'Mensaje demasiado largo')
    .required('Por favor incluye un mensaje breve'),
  email: yup
    .string()
    .trim()
    .max(120, 'Por favor captura tu email (demasiado largo)')
    .email('Por favor captura tu email')
    .required('Por favor captura tu email'),
});

const RecruitmentApplication = () => {
  const [stateFormData, setStateFormData] = useState();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const initialValues = {
    fullName: '',
    message: '',
    email: '',   
  };

  const onFileChange = (e) =>{
    if(e.target && e.target.files[0]) {
      if(e.target.files[0].size > 0 && e.target.files[0].size > 20971520){
        alert("El documento excede los 20 MB, por favor reduzca el tamano de su documento.");
      }  else {
        setStateFormData(e.target.files[0]);        
      }                   
    }
  };

  const onSubmit = (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("file", stateFormData);
    formData.append("fullName", values['fullName']);
    formData.append("email", values['email']);
    formData.append("subject", 'Subir CV');
    formData.append("body", values['message']);

    Axios({
      method: "post",
      url: 'https://bnswebapi.azurewebsites.net/EmailSender/Send',
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })    
    .then(res => {     
      setStateFormData({});
      setOpen(true);
      resetForm();      
    })
    .catch( error =>{
      console.log(error);
      setStateFormData({});
      setOpen(true);
      resetForm();
    });
    
    return values;
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box maxWidth={800} margin={'0 auto'} >
      <Box marginBottom={2}>
        <Typography
          variant={'h4'}
          sx={{ fontWeight: 700 }}
          gutterBottom
          align={'center'}
        >
          Sube tu CV o refiere a un candidato
        </Typography>
        <Typography color="text.secondary" align={'center'}>
          Te garantizamos que tan pronto como lo recibamos, buscaremos una vacante que se adecue a tu perfil.
        </Typography>
      </Box>
      <Box
        component={'form'}
        onSubmit={formik.handleSubmit}
        sx={{
          '& .MuiOutlinedInput-root.MuiInputBase-multiline': {
            padding: 0,
          },
          '& .MuiOutlinedInput-input': {
            background: theme.palette.background.paper,
            padding: 2,
          },
        }}
      >
      
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} data-aos="fade-up">
            <Typography
              variant="subtitle1"
              color="text.primary"
              fontWeight={700}
              gutterBottom
            >
              Nombre completo
            </Typography>
            <TextField
              placeholder="Nombre completo"
              variant="outlined"
              size="medium"
              name="fullName"
              fullWidth
              type="text"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              helperText={formik.touched.fullName && formik.errors.fullName}
            />
          </Grid>
          <Grid item xs={12} data-aos="fade-up">
            <Typography
              variant="subtitle1"
              color="text.primary"
              fontWeight={700}
              gutterBottom
            >
              E-mail
            </Typography>
            <TextField
              placeholder="Tu correo electrónico"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
         
          <Grid item xs={12} data-aos="fade-up">
            <Typography
              variant="subtitle1"
              color="text.primary"
              fontWeight={700}
              gutterBottom
            >
              Mensaje
            </Typography>
            <TextField
              placeholder="Preguntas acerca de vacantes, referidos, etc."
              variant="outlined"
              name="message"
              fullWidth
              multiline
              rows={4}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          <Grid item xs={12} sm={12} data-aos="fade-up">
            <Button
            variant="outlined"
            component="label"
            color="primary"
            fullWidth
            size="large"
            startIcon={
              <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                width={20}
                height={20}
              >
                <path
                  fillRule="evenodd"
                  d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </Box>
            }
          >
            Subir CV (Puedes subir zip para mas de un documento)
            
            <input type="file" style={{ display: 'none' }} accept={".gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.zip"}  onChange={onFileChange}/>
           
            </Button>
            <Box marginTop={2}>
           
            <Typography color="text.secondary" align={'center'}>
            { stateFormData
              ? stateFormData.name
              : ''
              }
              
            </Typography>
          </Box>
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="large"
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
   
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Documento recibido exitosamente.
          Recibirás un correo de seguimiento.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RecruitmentApplication;
