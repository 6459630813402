/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import { colors } from '@mui/material';
import Reclutamiento from '../../src/img/recruitment.png';
import illustration from '../../src/img/illustration.png';
import Calidad from '../../src/img/quality.png';
import Soporte from '../../src/img/technical-support.png';


const mock = [
  {
    color: colors.lightBlue[500],
    title: 'Reclutamiento',
    subtitle: 'Reclutamos a los mejores candidatos basados en tus necesidades.', 
    icon: Reclutamiento,
    url: '/reclutamiento'
  },
  {
    color: colors.purple[500],
    title: 'Diseño Web',
    subtitle: 'Diseñamos contenidos web, wireframes, mockups, prototipos, etc.',
    icon: illustration,
    url: '/contactus'
  },
  {
    color: colors.yellow[500],
    title: 'Quality Assurance',
    subtitle: 'Contamos con los mejores profesionales para el aseguramiento de la calidad.',
    icon: Calidad,
    url: '/contactus'
  },
  {
    color: colors.indigo[500],
    title: 'Soporte Técnico',
    subtitle: 'Ofrecemos diferentes niveles de soporte técnico general y especializado.',
    icon: Soporte,
    url: '/contactus'
  },
 
  
];

const Categories = () => {
  return (
    
    <Box>
      <Box marginBottom={4} marginTop={4}>
        
        <Typography
          variant="h5"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Estos son los servicios que ofrecemos para ti
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          ¿Quieres que te contactemos? Déjanos tus datos haciendo click aquí. 
          <br />
          
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)', cursor: 'pointer',
                  },
                }}
              >
                <Box
                  component={Card}
                  padding={4}
                  borderRadius={2}
                  width={1}
                  height={1}
                  data-aos={'fade-up'}
                  data-aos-delay={i * 100}
                  data-aos-offset={100}
                  data-aos-duration={600}
                >
                  <Box display={'flex'} flexDirection={'column'}>
                    <Box
                      component={Avatar}
                      width={60}
                      height={60}
                      marginBottom={2}
                      bgcolor={alpha(item.color, 0.1)}
                      color={item.color}
                      variant={'rounded'}
                      borderRadius={2}
                    >
                      <img alt='Bytes and software' 
                        src={
                          item.icon                      
                        }    
                        style={{
                          width: "60px", height: "55px"                          
                        }}                   
                      ></img>
                     
                    </Box>
                    <Typography
                      variant={'h6'}
                      gutterBottom
                      sx={{ fontWeight: 700 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.subtitle}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                      marginTop={2}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      <Button href={item.url}
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                      >
                        Mas información
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Categories;
