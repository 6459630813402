import React from 'react';
import Box from '@mui/material/Box';
import Main from './main-layout';
import Container from './container';
import RecruitmentMain from './recruitment-main';
import RecruitmentApplication from './recruitment-application'

const Recruitment = () => {
  return (
    <Main>
      <Container>
        <RecruitmentMain />
      </Container>
     
    </Main>
  );
};

export default Recruitment;
