import React from 'react';
import Box from '@mui/material/Box';
import Main from './main-layout';
import Container from './container';
import RecruitmentApplication from './recruitment-application'

const UploadResume = () => {
  return (
    <Main>      
      <Box bgcolor={'alternate.main'}>
        <Container>
          <RecruitmentApplication />
        </Container>
      </Box>     
    </Main>
  );
};

export default UploadResume;
